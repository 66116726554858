import React from 'react';
import Particles from 'react-particles-js';
import metagora_m from '../../images/Metagora_M.svg'

const Canvas = () => {
    console.log(metagora_m)
  return (
    <Particles
        className="particle_field"
        params={{
            "fps_limit": 28,
            "particles": {
                "collisions": {
                    "enable": false
                },
                "number": {
                    "value": 200,
                    "density": {
                        "enable": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": 130,
                    "opacity": 0.2,
                    "width": 0.5
                },
                "move": {
                    "speed": 1
                },
                "opacity": {
                    "anim": {
                        "enable": true,
                        "opacity_min": 0.1,
                        "speed": 1,
                        "sync": false
                    },
                    "value": 0.2
                },
                "shape": {
                    "type": "circle", // Can show circle, edge (a square), triangle, polygon, star, img, or an array of multiple.
                    "stroke": { // The border
                      "width": 10,
                      "color": "#145ea8"
                    },
                    "polygon": { // if the shape is a polygon
                      "nb_sides": 5
                    },
                    "image": { // If the shape is an image
                      "src": "",
                      "width": 100,
                      "height": 100
                    }
                },
            },
            // "polygon": {
            //     "enable": true,
            //     "scale": 0.5,
            //     "type": "inline",
            //     "move": {
            //         "radius": 10
            //     },
            //     "url": metagora_m,
            //     "inline": {
            //         "arrangement": "equidistant"
            //     },
            //     "draw": {
            //         "enable": true,
            //         "stroke": {
            //             "color": "rgba(255, 255, 255, .2)"
            //         }
            //     }
            // },
            "retina_detect": false,
            "interactivity": {
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": ["bubble", "grab"]
                    },
                    "onclick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "resize": true
                },
                "modes": {
                    "bubble": {
                        "size": 6,
                        "distance": 40
                    }
                }
            }
        }} />
  )
}


const ParticlesField = () => {
    return (
        <Canvas />
    )
}

export default ParticlesField
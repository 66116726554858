import React from "react"
// import { withAuthenticator } from '@aws-amplify/ui-react'
import "./App.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Header, Container, Grid } from "semantic-ui-react"
import Particles from "./components/Particles"

const Home = () => (
  <Container textAlign="center">
    <Grid verticalAlign="middle" stretched style={{ height: "100vh" }}>
      <Grid.Column>
        <Header as="h1" inverted>
          <span
            style={{ backgroundColor: "black", padding: 3, fontSize: "1.5em" }}
          >
            M
          </span>
          ETAGORA
        </Header>
      </Grid.Column>
    </Grid>
  </Container>
)

function App() {
  return (
    <Router>
      <Particles />
      <Container>
        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
      </Container>
    </Router>
  )
}

export default App
